<template>
  <div>
    <div class="card h-full">
      <div class="card-header">
        <div>
          <div class="card-title">
            {{ $t('page.analytics.hashtags_history.title') }}
          </div>
          <small
            v-html="$t('page.analytics.hashtags_history.description-html')"
          />
        </div>
        <div>
          <base-popover>
            <template v-slot:button>
              <i class="far fa-question-circle text-blue-600"></i>
            </template>
            <div
              class="popover-body"
              v-html="$t('page.analytics.hashtags_history.helper-html')"
            />
          </base-popover>
        </div>
      </div>
      <div class="card-body">
        <div class="flex md:justify-end mb-8">
          <div class="w-full md:w-48">
            <Multiselect
              v-model="period"
              :options="filterOptions"
            />
          </div>
        </div>
        <div v-if="hasData" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-8 gap-y-2 my-4 max-h-96 overflow-y-auto">
          <div
            v-for="hashtag in hashtags"
            :key="hashtag.name"
          >
            <div class="flex justify-between items-center">
              <label class="text-sm">{{ hashtag.name }}</label>
              <div class="text-sm font-semibold">{{ hashtag.count }}</div>
            </div>
            <div class="progress border border-gray-200 h-2 shadow-md rounded-full mt-1 mb-2">
              <div
                class="progress-bar rounded-full"
                :class="hashtag.color"
                :style="{ width: hashtag.rate + '%' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs, unref } from 'vue'
import Multiselect from '@vueform/multiselect'
import BasePopover from '../base/BasePopover.vue'
import { useNumeral } from '@/composables/useNumeral'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    Multiselect,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { t } = useI18n()

    const size = ref(24)
    const period = ref('week')
    const filterOptions = ref({
      week: t('sentence.last_week'),
      month: t('sentence.last_month'),
      halfYear: t('sentence.last_semester')
    })

    const {
      counter
    } = useNumeral()
    
    const hasData = computed(() => {
      return unref(socialNetworkData) && unref(socialNetworkData).mostEngagedHashtags
    })

    const historyOfHashtags = computed(() => {
      if (unref(hasData)) {
        const mostEngagedHashtags = unref(socialNetworkData).mostEngagedHashtags[unref(period)]
        const hashtags = Object.entries(mostEngagedHashtags)
          .sort((a: Array<any>, b: Array<any>) => {
            return b[1] - a[1]
          })
          .map(h => ({
            name: h[0],
            count: h[1] as number
          }))
        return hashtags
      }
      return []
    })

    const limits = computed(() => {
      const result = {
        good: 0,
        bad: 0
      }
      const totalHashtags = unref(historyOfHashtags).length
      if (totalHashtags) {
        const max = unref(historyOfHashtags)[0].count as number
        const min = unref(historyOfHashtags)[totalHashtags - 1].count as number
        const range = (max - min) / 3
        result.good = range * 2
        result.bad = range
      }
      return result
    })

    const hashtags = computed(() => {
      const selectedHashtags = unref(historyOfHashtags).slice(0, unref(size))
      const sum = selectedHashtags.reduce((current, previous) => {
        return current + previous.count
      }, 0)

      return selectedHashtags
        .map(hashtag => {
          return {
            ...hashtag,
            count: counter(hashtag.count),
            color: getHashtagColor(hashtag.count),
            rate: (hashtag.count / sum) * 100
          }
        })
    })

    const getHashtagColor = (value: number) => {
      if (value === 0 || value < unref(limits).bad) {
        return 'bg-red-600'
      } else if (value >= unref(limits).good) {
        return 'bg-green-400'
      } else {
        return 'bg-yellow-400'
      }
    }

    return {
      hasData,
      period,
      filterOptions,
      hashtags,
      getHashtagColor
    }
  }
})
</script>
