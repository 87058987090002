
import { computed, defineComponent, toRefs, unref } from 'vue'
import PostView from './PostView.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import BasePopover from '../base/BasePopover.vue'

// install Swiper modules
SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    PostView,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    dataOnly: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { socialNetworkData } = toRefs(props)
    
    const posts = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).bestPosts) {
        const bestPosts = unref(socialNetworkData).bestPosts
        return bestPosts
      }
      return []
    })

    const swiperSettings = {
      loop: false,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 4
        },
        1536: {
          slidesPerView: 5
        }
      }
    }

    return {
      posts,
      swiperSettings
    }
  }
})
