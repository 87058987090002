import { useStore } from 'vuex'
import { computed, toRaw } from 'vue'
// import { useProfile } from '../profile/useProfile'
import { toDate } from '../utils/toDate'
import { useAxios } from '@/composables/useAxios'

const END_POINT = process.env.VUE_APP_ANALYTICS_ENDPOINT

export type PostMediaType = 'VIDEO' | 'IMAGE' | 'CAROUSEL_ALBUM'

export interface Post {
  caption: string
  dateUtc: Date
  hashtags: string // "#thecrazymayo|#maionesedeverdade|#maioneseartesanal|#amomaionese|#santos|#santoscity|#sãovicente"
  media: {
    thumbnail: string,
    type: PostMediaType,
    url: string
  }
  insights: {
    comments: number
    engagement: number
    exits: number
    impressions: number
    likes: number
    reach: number
    referrals: number
    replies: number
    saved: number
    shares: number
    taps_back: number
  }
  postUrl: string
  source: string
}

interface Story {
  answers: number
  date: Date
  engagement: number
  exits: number
  id: string
  impressions: number
  media: {
    thumbnail: string
    type: string // 'VIDEO'
    url: string
  }
  reach: number
  referrals: number
  retention: number
  taps_back: number
}

enum PostsChunkPeriodsEnum {
  postsLast30Days = 'postsLast30Days',
  posts30to60DaysAgo = 'posts30to60DaysAgo',
}

type PostsChunkPeriods = {
  [key in PostsChunkPeriodsEnum]: Post[]
}

enum StoriesChunkPeriodsEnum {
  storiesLast30Days = 'storiesLast30Days',
  stories30to60DaysAgo = 'stories30to60DaysAgo',
}

type StoriesChunkPeriods = {
  [key in StoriesChunkPeriodsEnum]: Story[]
}

interface IndividualInsight {
  insightLast30Days: number
  insight30to60DaysAgo: number
  difference: number
}

interface MostEngagedHashtags {
  [period: string]: string
}
interface Competitor {
  bestPosts: Post[]
  posts: Post[]
  bestScoreTimes: any
  engagementRate: number
  fans: number
  average: number
  max: number
  min: number
  mostEngagedHashtags: MostEngagedHashtags
  postCount: number
  totalComments: number
  totalLikes: number
  totalShares: number
  userName: string
  biography: string
  fullName: string
  profilePicUrl: string
}

export interface SocialCompetitorsInterface {
  [source: string]: Competitor[];
}

function calculatePercentageDifference(valueA: number, valueB: number): number {
  return ((valueB - valueA) / valueA) * 100;
}

const chunkPostsInPeriods = (posts: Post[]): PostsChunkPeriods => {
  const aMonthAgo = new Date();
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);

  const sixtyDaysAgo = new Date();
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

  return {
    postsLast30Days: posts.filter(post => toDate(post.dateUtc) > aMonthAgo),
    posts30to60DaysAgo: posts.filter(post => toDate(post.dateUtc) <= aMonthAgo && toDate(post.dateUtc) > sixtyDaysAgo)
  }
}

const chunkStoriesInPeriods = (stories: Story[] = []): StoriesChunkPeriods => {
  const aMonthAgo = new Date();
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);

  const sixtyDaysAgo = new Date();
  sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

  const storiesWithDate = stories.map(story => ({
    ...story,
    date: story.date ? toDate(story.date) : new Date()
  }))

  return {
    storiesLast30Days: storiesWithDate.filter(story => story.date > aMonthAgo),
    stories30to60DaysAgo: storiesWithDate.filter(story => story.date <= aMonthAgo && story.date > sixtyDaysAgo)
  }
}

export const getAnalytics = () => {
  const store = useStore()

  // const {
  //   isActive
  // } = useProfile()

  // watchEffect(() => {
  //   console.log({isActive: isActive.value})
  //   store.dispatch('analytics/fetchAnalytics', isActive.value)
  //   store.dispatch('analytics/fetchCompetitors')
  // })

  const analytics = computed(() => {
    return toRaw(store.getters['analytics/analytics'])
  })

  const isEmpty = computed(() => {
    return toRaw(store.getters['analytics/isEmpty'])
  })

  const isLoaded = computed(() => {
    return toRaw(store.getters['analytics/isLoaded'])
  })

  const isEmptyCompetitors = computed(() => {
    return toRaw(store.getters['analytics/isEmptyCompetitors'])
  })

  const isLoadedCompetitors = computed(() => {
    return toRaw(store.getters['analytics/isLoadedCompetitors'])
  })

  const competitors = computed(() => {
    const competitors = toRaw(store.state.analytics.competitors)
    // const competitors = toRaw(store.getters['analytics/competitors'])
    return competitors
  })
  
  const getInsights = (input: Post[] = [], type: keyof Post["insights"] ): IndividualInsight => {
    const posts: PostsChunkPeriods = chunkPostsInPeriods(input)

    const insightLast30Days = posts.postsLast30Days.reduce((prev, curr) => {
      return prev += curr.insights[type]
    }, 0)

    const insight30to60DaysAgo = posts.posts30to60DaysAgo.reduce((prev, curr) => {
      return prev += curr.insights[type]
    }, 0)
    
    return {
      insightLast30Days: insightLast30Days,
      insight30to60DaysAgo: insight30to60DaysAgo,
      difference: calculatePercentageDifference(insight30to60DaysAgo, insightLast30Days)
    }
  }

  const getMediaTypeDistribution = (input: Post[] = []): Record<PostMediaType, number> => {
    const posts: PostsChunkPeriods = chunkPostsInPeriods(input)

    const count: Record<PostMediaType, number> = {
      VIDEO: 0,
      IMAGE: 0,
      CAROUSEL_ALBUM: 0
    };
  
    for (const post of posts.postsLast30Days) {
      if (post.media.type in count) {
        count[post.media.type]++;
      }
    }
  
    return count;
  }

  const getMediaEngagement = (input: Post[] = []): Record<PostMediaType, number> => {
    const posts: PostsChunkPeriods = chunkPostsInPeriods(input)

    const count: Record<PostMediaType, number> = {
      VIDEO: 0,
      IMAGE: 0,
      CAROUSEL_ALBUM: 0
    };
  
    for (const post of posts.posts30to60DaysAgo) {
      if (post.media.type in count) {
        count[post.media.type] += post.insights.engagement;
      }
    }
  
    return count;
  }

  const calcEngagementRate = (input: Post[] = [], followersCount: number = 0): number => {
    if (followersCount === 0) {
      return 0
    }

    let engagementRate = 0

    const last12Posts = input
      .sort((a: Post, b: Post) => toDate(b.dateUtc).getTime() - toDate(a.dateUtc).getTime())
      .slice(0, 12)

      const engagement = last12Posts.reduce((prev: any, curr: any) => {
        return prev + curr.insights?.engagement || 0
      }, 0)

    engagementRate = ((engagement / 12) / followersCount) * 100

    return engagementRate
  }

  const getColorForPostMediaType = (type: string): string => {
    const colors: Record<string, string> = {
      VIDEO: '#ff0000', // Vermelho para VIDEO
      IMAGE: '#00ff00', // Verde para IMAGE
      CAROUSEL_ALBUM: '#0000ff' // Azul para CAROUSEL_ALBUM
    };
    return colors[type] || '#000000'; // Cor padrão caso o tipo não seja encontrado
  }

  const getStoriesInsights = (stories: Story[] = [], metric: string): IndividualInsight | null => {
    const chunks: StoriesChunkPeriods = chunkStoriesInPeriods(stories)

    const insightLast30Days = chunks.storiesLast30Days.reduce((prev, curr) => {
      return prev += curr[metric as keyof Story] as number
    }, 0)

    const insight30to60DaysAgo = chunks.stories30to60DaysAgo.reduce((prev, curr) => {
      return prev += curr[metric as keyof Story] as number
    }, 0)
    
    return {
      insightLast30Days: insightLast30Days,
      insight30to60DaysAgo: insight30to60DaysAgo,
      difference: calculatePercentageDifference(insight30to60DaysAgo, insightLast30Days) || 0
    }
  }

  const fetchAccountThumbnail = async (profileId: string, accountId: string, source: string) => {
    try {
      const { httpFunctions } = await useAxios({config: {baseURL: END_POINT, useCache: true}})
  
      const { data: response } = await httpFunctions.put(`/manyContent/update-page/${profileId}/${source}/${accountId}`)
  
      return response.profilePicUrl
    } catch (error) {
      return null
    }
  }

  const fetchPostThumbnail = async (profileId: string, postId: string, source: string) => {
    try {
      const { httpFunctions } = await useAxios({config: {baseURL: END_POINT, useCache: true}})
  
      const { data: response } = await httpFunctions.put(`/manyContent/update-post/${profileId}/${source}/${postId}`)
      
      switch (response.media?.type) {
        case 'IMAGE':
        case 'CAROUSEL_ALBUM':
          return response.media?.url
        case 'VIDEO':
          return response.media?.thumbnail
        default:
          return null
      }
    } catch (error) {
      return null
    }
  }

  return {
    analytics,
    competitors,
    isEmpty,
    isLoaded,
    isEmptyCompetitors,
    isLoadedCompetitors,
    getInsights,
    getMediaTypeDistribution,
    getMediaEngagement,
    getColorForPostMediaType,
    getStoriesInsights,
    calcEngagementRate,
    fetchAccountThumbnail,
    fetchPostThumbnail,
  }
}
