

import { computed, defineComponent, toRefs, unref } from 'vue'
import { useNumeral } from '@/composables/useNumeral'
import { toDate } from '@/composables/utils/toDate'
// eslint-disable-next-line no-unused-vars
import { Post, getAnalytics } from '@/composables/analytics/useAnalytics'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { useProfile } from '@/composables/profile/useProfile'

const getPostsCountByWeek = (posts: Post[]): number => {
  function getWeek(date: Date): string {
    const firstYearDay = new Date(date.getFullYear(), 0, 1);
    const dias = Math.floor((date.getTime() - firstYearDay.getTime()) / (24 * 60 * 60 * 1000)) + ((firstYearDay.getDay() + 6) % 7);
  
    const week = Math.ceil(dias / 7);
    return `${date.getFullYear()}-${week}`;
  }

  const aMonthAgo = new Date();
  aMonthAgo.setDate(aMonthAgo.getDate() - 30);
  
  const recentPosts: Post[] = (posts || []).filter((post: Post) => toDate(post.dateUtc) > aMonthAgo)
  const groups = new Map<string, Post[]>()
  
  for (const post of recentPosts) {
    const week = getWeek(toDate(post.dateUtc));
    const group = groups.get(week) || [];
    group.push(post);
    groups.set(week, group);
  }

  let totalPosts = 0;
  groups.forEach(group => {
    totalPosts += group.length;
  });

  const average = groups.size > 0 ? totalPosts / groups.size : 0;
  return average;
}

export default defineComponent({
  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    showEngagement: {
      type: Boolean,
      default: false
    }
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { profile } = useProfile()

    const { t } = useI18n()

    const {
      counter,
      format
    } = useNumeral()

    const {
      calcEngagementRate,
      fetchAccountThumbnail
    } = getAnalytics()

    const basicInfo = computed(() => {
      const account = unref(socialNetworkData)
      const basicInfo = {
        thumbnail: '',
        username: '',
        name: '',
        biography: '',
        fansCount: '0',
        postCount: '0',
        postsByWeek: '0',
      }
      if (account && Object.keys(account).length) {
        basicInfo.thumbnail = account?.profilePicUrl || '',
        basicInfo.username = account?.userName || '',
        basicInfo.name = account?.fullName || '',
        basicInfo.biography = account?.biography || '',
        basicInfo.fansCount = counter(account?.fans || '0') as string,
        basicInfo.postCount = counter(account?.postCount || '0') as string,
        basicInfo.postsByWeek = counter(getPostsCountByWeek(account?.posts || [])) as string
      }
      return basicInfo
    })

    const engagementRate = computed(() => {
      const rate = calcEngagementRate(unref(socialNetworkData).posts, unref(socialNetworkData).fans)
      return {
        value: format(rate, '0.00'),
        insideRange: isEngagementRateInsideRage(rate, unref(socialNetworkData).fans)
      }
    })

    const isEngagementRateInsideRage = (followers: number, rate: number) => {
      if (followers < 1000) {
        return rate > 8
      }

      if (followers < 5000) {
        return rate > 5.7
      }

      if (followers < 10000) {
        return rate > 4
      }

      if (followers < 100000) {
        return rate > 2.4
      }

      return rate > 1.7
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')

      try {
        const account = unref(socialNetworkData)
        const thumb = await fetchAccountThumbnail(profile.value.id, account.userId, account.source)
        if (thumb) {
          element.src = thumb
        }
      } catch (error) {
        console.log(error)
      }
    }

    const showLegend = () => {
      Swal.fire({
        confirmButtonText: t('state.engagement_description.action_confirm'),
        html: t('state.engagement_description.message-html'),
      })
    }

    return {
      basicInfo,
      imageFallback,
      engagementRate,
      showLegend
    }
  }
})
