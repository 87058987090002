export default (social: string | undefined) => {
  switch (social) {
    case 'facebook':
      return '#3b5999'
    case 'instagram':
      return '#e4405f'
    case 'linkedin':
      return '#0077B5'
    case 'twitter':
      return '#55acee'
    default:
      return '#f3f4f9'
  }
}