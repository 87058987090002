
import { computed, defineComponent, toRefs, unref } from 'vue'
import VueApexCharts from "vue3-apexcharts";
// eslint-disable-next-line no-unused-vars
import { PostMediaType, getAnalytics } from '@/composables/analytics/useAnalytics';

export default defineComponent({
  components: {
    apexchart: VueApexCharts
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
  },
  
  setup (props) {
    const { socialNetworkData } = toRefs(props)

    const { getMediaTypeDistribution, getColorForPostMediaType } = getAnalytics();
    
    const mediaTypeDistribution = computed(() => {
      return getMediaTypeDistribution(unref(socialNetworkData).posts)
    })

    const mediaTypeDistributionData = computed(() => {
      const mediaTypeDistributionData = Object.entries(unref(mediaTypeDistribution)).map(([type, count]) => ({
        type: type as PostMediaType,
        count,
        color: getColorForPostMediaType(type)
      }))

      return mediaTypeDistributionData
    })

    const series = computed(() => {
      return unref(mediaTypeDistributionData).map(data => data.count)
    })

    const chartOptions = computed(() => {
      return {
        chart: {
          type: 'donut'
        },
        colors: unref(mediaTypeDistributionData).map((g: any) => g.color),
        labels: Object.keys(unref(mediaTypeDistribution)).map(type => type.toUpperCase()),
        legend: {
          formatter: (val: any, opts: any) => {
            return `${val} - ${opts.w.globals.series[opts.seriesIndex]}`
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    })

    return {
      series,
      chartOptions
    }
  }
})
