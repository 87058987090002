<template>
  <div class="flex flex-wrap">
    <div class="w-full relative">
      <button
        @click="togglePopover()"
        @mouseover="active = true"
        @mouseleave="active = false"
      >
        <slot name="button">
          Clique aqui
        </slot>
      </button>
      <div
        class="popover"
        :class="{'hidden': !active, 'block': active}"
      >
        <div>
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  setup () {
    const {
      isActive: active,
      toggle: togglePopover
    } = useToggle(false)

    return {
      togglePopover,
      active
    }
  }
})
</script>
