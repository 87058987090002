
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
// import AnalyticsPageMenu from '@/components/analytics/AnalyticsPageMenu.vue'
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import BasicInfo from '@/components/analytics/BasicInfo.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useI18n } from 'vue-i18n'
import MediaTypeDistributionChart from '@/components/analytics/MediaTypeDistributionChart.vue'
import MediaTypeEngagementChart from '@/components/analytics/MediaTypeEngagementChart.vue'
import HistoryOfPublicationTimes from '@/components/analytics/historyOfPublicationTimes.vue'
import HistoryOfHashtags from '@/components/analytics/HistoryOfHashtags.vue'
import BestPosts from '@/components/analytics/BestPosts.vue'
import OfferModal from '@/components/freeTrial/OfferModal.vue'
import OfferAlertWrapper from '@/components/freeTrial/OfferAlertWrapper.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    DashboardContent,
    // AnalyticsPageMenu,
    OfferModal,
    OfferAlertWrapper,
    BasicInfo,
    MediaTypeDistributionChart,
    MediaTypeEngagementChart,
    HistoryOfPublicationTimes,
    HistoryOfHashtags,
    BestPosts
},

  setup () {
    const selectedNetworkName = ref<string>('instagram')
    const selectedCompetitor = ref<string>('')

    const { t } = useI18n()

    const store = useStore()

    const {
      competitors,
      isLoadedCompetitors,
      isEmptyCompetitors,
      fetchAccountThumbnail
    } = getAnalytics()

    const {
      profile,
      isFreeTrial,
      isFreeTrialActive,
      hasActiveSubscription,
      lockAccess,
      isActive
    } = useProfile()

    watchEffect(() => {
      store.dispatch('analytics/fetchCompetitors', isActive.value)
    })

    const hasSelectedSocialNetwork = computed(() => {
      return unref(selectedNetworkName) !== ''
    })
    
    const competitorsList = computed(() => {
      const list: any[] = []

      if (unref(competitors)[unref(selectedNetworkName)]?.length) {
        unref(competitors)?.[unref(selectedNetworkName)]?.map((competitor: any) => {
          list.push({
            id: competitor.userId,
            userName: competitor.userName,
            thumbnail: competitor.profilePicUrl,
            active: competitor.id === selectedCompetitor.value
          })
        })
      }
      return list
    })

    const selectedCompetitorData = computed(() => {
      const competitorId = unref(selectedCompetitor)
      
      const selected = unref(competitors)?.[unref(selectedNetworkName)]?.find((c: {userId: string}) => c.userId === competitorId)
     
      return selected || {}
    })

    const alertMessage = computed(() => {
      if (isFreeTrial.value && !isFreeTrialActive.value) {
        return t('state.finish_free_trial')
      } else if (!hasActiveSubscription.value) {
        return t('state.subscription_expired')  
      }
      return ''
    })

    const changeCompetitor = (id: string) => {
      selectedCompetitor.value = id
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')

      try {
        const userId = e.target.getAttribute('data-id');
        const thumb = await fetchAccountThumbnail(profile.value.id, userId!, selectedNetworkName.value)
        if (thumb) {
          element.src = thumb
        }
      } catch (error) {
        console.log(error)
      }
    }

    watchEffect(() => {
      if (competitorsList.value.length && selectedCompetitor.value === '') {
        changeCompetitor(competitorsList.value[0].id)
      }
    })

    return {
      hasSelectedSocialNetwork,
      selectedNetworkName,
      selectedCompetitorData,
      competitorsList,
      isLoadedCompetitors,
      isEmptyCompetitors,
      alertMessage,
      lockAccess,
      changeCompetitor,
      imageFallback
    }
  }
})
