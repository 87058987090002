import {
  addDays,
  addMonths,
  startOfWeek,
  startOfYear
} from 'date-fns'
import { useDateFns } from './useDateFns'


export const getMonths = (mask = 'MMM') => {
  const { format } = useDateFns()
  const firstMonth = startOfYear(new Date())

  return Array
    .from(Array(12))
    .map((e, i) => 
      format(addMonths(firstMonth, i), mask)
      )
    }
    
export const getWeekdays = (mask = 'EEEE') => {
  const { format } = useDateFns()
  const firstDOW = startOfWeek(new Date())

  return Array
    .from(Array(7))
    .map((e, i) => 
      format(addDays(firstDOW, i), mask)
    )
}