<template>
  <div>
    <div class="mt-12 w-full flex justify-between items-start"
      v-if="!dataOnly"
    >
      <h3>
        {{ $t('page.analytics.best_posts.title') }}
      </h3>
      <div>
        <base-popover>
          <template v-slot:button>
            <i class="far fa-question-circle text-blue-600"></i>
          </template>
          <div
            class="popover-body"
            v-html="$t('page.analytics.best_posts.helper-html')"
          />
        </base-popover>
      </div>
    </div>
    <swiper
      v-bind="swiperSettings"
      navigation
    >
      <swiper-slide
        class="p-2"
        v-for="post in posts"
        :key="post.id"
      >
        <post-view :post="post" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs, unref } from 'vue'
import PostView from './PostView.vue'

// @ts-ignore
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation } from 'swiper'
import BasePopover from '../base/BasePopover.vue'

// install Swiper modules
SwiperCore.use([Navigation])

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
    PostView,
    BasePopover
  },

  props: {
    socialNetworkData: {
      type: Object,
      default: () => ({})
    },
    dataOnly: {
      type: Boolean,
      default: false
    }
  },

  setup (props) {
    const { socialNetworkData } = toRefs(props)
    
    const posts = computed(() => {
      if (unref(socialNetworkData) && unref(socialNetworkData).bestPosts) {
        const bestPosts = unref(socialNetworkData).bestPosts
        return bestPosts
      }
      return []
    })

    const swiperSettings = {
      loop: false,
      slidesPerView: 1,
      slideToClickedSlide: true,
      preventInteractionOnTransition: true,
      freeMode: true,
      breakpoints: {
        640: {
          slidesPerView: 2
        },
        1024: {
          slidesPerView: 2
        },
        1280: {
          slidesPerView: 4
        },
        1536: {
          slidesPerView: 5
        }
      }
    }

    return {
      posts,
      swiperSettings
    }
  }
})
</script>
