<template>
  <dashboard-content>
    <!-- <template v-slot:actions>
      <analytics-page-menu v-model="selectedNetworkName"/>
    </template> -->

    <div class="flex flex-row space-x-2 md:space-x-0" v-if="isLoadedCompetitors">
      <button
        v-for="competitor in competitorsList"
        :key="competitor.id"
        class="select-button flex items-center"
        :class="{ active: competitor.active }"
        @click="changeCompetitor(competitor.id)"
        >
        <img :src="competitor.thumbnail" class='h-8 w-8 rounded-full' :data-id="competitor.id" @error="imageFallback" />
        <span class="hidden lg:block">{{ competitor.userName }}</span>
      </button>
    </div>

    <div v-if="isLoadedCompetitors && hasSelectedSocialNetwork && competitorsList.length">
      <div class="my-12">
        <basic-info :socialNetworkData="selectedCompetitorData" show-engagement />
      </div>

      <div class="my-4 grid grid-cols-1 lg:grid-cols-2 gap-4">
        <MediaTypeDistributionChart :socialNetworkData="selectedCompetitorData" />
        <MediaTypeEngagementChart :socialNetworkData="selectedCompetitorData" />
      </div>

      <div class="my-4">
        <reaction-to-content :socialNetworkData="selectedCompetitorData" />
        <history-of-publication-times
          class="lg:col-span-2"
          :socialNetworkData="selectedCompetitorData"
          :socialNetworkName="selectedNetworkName" />
      </div>

      <div class="my-4">
        <history-of-hashtags :socialNetworkData="selectedCompetitorData" />
      </div>

      <div class="my-12">
        <h2>{{ $t('page.analytics.session_performance.sentences.title') }}</h2>
      </div>

      <div class="my-4">
        <best-posts :socialNetworkData="selectedCompetitorData" />
      </div>
    </div>

    <div
      class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-3/4"
      v-if="isLoadedCompetitors && isEmptyCompetitors"
    >
      <div class="text-lg text-center leading-relaxed" v-if="lockAccess">
        <p class="text-base font-semibold leading-snug">
          <i class="fas fa-info-circle text-blue-400"></i>
          {{ $t('page.review.sentence.unavailable_resource') }}
        </p>

        <offer-modal offer="analyticsOffer" :startActive="false">
          <template v-slot:button >
            <button
              class="btn btn-primary mt-12 mx-auto"
              @click.prevent="toggleOfferModal"
            >
              {{ $t('page.review.action.unavailable_resource') }}
            </button>
          </template>
        </offer-modal>
      </div>

      <div class="text-lg text-center leading-relaxed" v-else>
        <p
          v-html="$t('page.analytics.sentence.no_competitors-html')"
        />
        <router-link
          class="btn btn-primary mt-12 mx-auto w-max"
          :to="{ name: 'settings-profile' }"
        >
          {{ $t('nav.sidebar.settings') }}
        </router-link>
      </div>

      <div class="text-sm text-center mt-12">
        <base-you-need-help-message />
      </div>
    </div>

    <div
      class="flex flex-col justify-center items-center mx-auto"
      v-if="!isLoadedCompetitors"
    >
      <div class="my-24">
        <i class="fas fa-spinner fa-spin"></i> {{ $t('sentence.loading') }}
      </div>
    </div>

    <template
      v-slot:notification
      v-if="lockAccess"
    >
      <offer-alert-wrapper>
        {{ alertMessage }}
      </offer-alert-wrapper>
      <offer-modal offer="analyticsOffer" hide-button/>
    </template>

    
  </dashboard-content>
</template>

<script lang="ts">
import { computed, defineComponent, ref, unref, watchEffect } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
// import AnalyticsPageMenu from '@/components/analytics/AnalyticsPageMenu.vue'
import { getAnalytics } from '@/composables/analytics/useAnalytics'
import BasicInfo from '@/components/analytics/BasicInfo.vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useI18n } from 'vue-i18n'
import MediaTypeDistributionChart from '@/components/analytics/MediaTypeDistributionChart.vue'
import MediaTypeEngagementChart from '@/components/analytics/MediaTypeEngagementChart.vue'
import HistoryOfPublicationTimes from '@/components/analytics/historyOfPublicationTimes.vue'
import HistoryOfHashtags from '@/components/analytics/HistoryOfHashtags.vue'
import BestPosts from '@/components/analytics/BestPosts.vue'
import OfferModal from '@/components/freeTrial/OfferModal.vue'
import OfferAlertWrapper from '@/components/freeTrial/OfferAlertWrapper.vue'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    DashboardContent,
    // AnalyticsPageMenu,
    OfferModal,
    OfferAlertWrapper,
    BasicInfo,
    MediaTypeDistributionChart,
    MediaTypeEngagementChart,
    HistoryOfPublicationTimes,
    HistoryOfHashtags,
    BestPosts
},

  setup () {
    const selectedNetworkName = ref<string>('instagram')
    const selectedCompetitor = ref<string>('')

    const { t } = useI18n()

    const store = useStore()

    const {
      competitors,
      isLoadedCompetitors,
      isEmptyCompetitors,
      fetchAccountThumbnail
    } = getAnalytics()

    const {
      profile,
      isFreeTrial,
      isFreeTrialActive,
      hasActiveSubscription,
      lockAccess,
      isActive
    } = useProfile()

    watchEffect(() => {
      store.dispatch('analytics/fetchCompetitors', isActive.value)
    })

    const hasSelectedSocialNetwork = computed(() => {
      return unref(selectedNetworkName) !== ''
    })
    
    const competitorsList = computed(() => {
      const list: any[] = []

      if (unref(competitors)[unref(selectedNetworkName)]?.length) {
        unref(competitors)?.[unref(selectedNetworkName)]?.map((competitor: any) => {
          list.push({
            id: competitor.userId,
            userName: competitor.userName,
            thumbnail: competitor.profilePicUrl,
            active: competitor.id === selectedCompetitor.value
          })
        })
      }
      return list
    })

    const selectedCompetitorData = computed(() => {
      const competitorId = unref(selectedCompetitor)
      
      const selected = unref(competitors)?.[unref(selectedNetworkName)]?.find((c: {userId: string}) => c.userId === competitorId)
     
      return selected || {}
    })

    const alertMessage = computed(() => {
      if (isFreeTrial.value && !isFreeTrialActive.value) {
        return t('state.finish_free_trial')
      } else if (!hasActiveSubscription.value) {
        return t('state.subscription_expired')  
      }
      return ''
    })

    const changeCompetitor = (id: string) => {
      selectedCompetitor.value = id
    }

    const imageFallback = async (e: Event & { target: HTMLFormElement }) => {
      const element = e.target
      element.src = require('@/assets/profile/manyrobot-profile.svg')

      try {
        const userId = e.target.getAttribute('data-id');
        const thumb = await fetchAccountThumbnail(profile.value.id, userId!, selectedNetworkName.value)
        if (thumb) {
          element.src = thumb
        }
      } catch (error) {
        console.log(error)
      }
    }

    watchEffect(() => {
      if (competitorsList.value.length && selectedCompetitor.value === '') {
        changeCompetitor(competitorsList.value[0].id)
      }
    })

    return {
      hasSelectedSocialNetwork,
      selectedNetworkName,
      selectedCompetitorData,
      competitorsList,
      isLoadedCompetitors,
      isEmptyCompetitors,
      alertMessage,
      lockAccess,
      changeCompetitor,
      imageFallback
    }
  }
})
</script>

<style scoped>
@layer components {
  .select-button {
    @apply flex justify-center items-center space-x-2 rounded-full bg-transparent capitalize text-gray-600 text-sm font-bold transition duration-300 ease-in-out;
    @apply py-0 lg:py-2 px-0 lg:px-6 w-auto h-8 lg:h-12;
  }
  .select-button.active {
    @apply bg-blue-100 border border-blue-400 shadow-md;
  }
}
</style>
