
import { defineComponent } from 'vue'
import { useToggle } from '@/composables/useToggle'

export default defineComponent({
  setup () {
    const {
      isActive: active,
      toggle: togglePopover
    } = useToggle(false)

    return {
      togglePopover,
      active
    }
  }
})
